/* Style for the game board */
.game-board {
  display: grid;
  grid-template-columns: 22.25vw 22.25vw 22.25vw 22.25vw; /* Adjust based on number of cards */
  gap: 10px;
  margin: 20px;
}

/* Style for individual cards */
.card {
  width: 22.25vw; /* Adjust size as needed */
  height: 22.25vw;
  background-color: #f0f0f0;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  font-size: 90px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

button {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
  cursor: pointer;
  color: black;
}

.card.flipped {
  background-color: #4CAF50;
  color: white;
}

/* Add a flip effect to cards */
.card.flipped {
  transform: rotateY(180deg);
}

/* Style for the timer display */
.timer {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.game-container {
  display: flex;
  justify-content: center;
}

.header-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.hero {
  font-size: 42px;
  font-weight: bold;
}

.date {
  font-size: 28px;
  font-weight: 300;
  line-height: 42px;
}

.options-container {
  position: absolute;
  right:8px;
  bottom: 0;
}

.stage-button {
  display: flex;
  flex: 1;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  height: 22.25vw;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
  border: none;
}

.le {
  color: #4CAF50;
}

.how-to {
  border: none;       /* Removes the border */
  padding: 0;         /* Removes the padding */
  margin: 0;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  background-color: #f0f0f0;
  border-radius: 40px;
  width: 30px;
}

.how-to-modal {
  position: relative;
}


li {
  margin-bottom: 10px;
}

.fact {
  margin-bottom: 10px;
  margin-top: 10px;
}

.scrim {
  position: fixed;  /* Positioned relative to the viewport */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black */
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
}

.modal {
  width: 80%;   /* or any desired width */
  padding: 20px;
  max-height: 95%;
  overflow: scroll;
  border-radius: 8px;
  background-color: white;
}

.modal-fullscreen {
  width: 100%;   /* or any desired width */
}

.winning-modal-content {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.congratulations {
  font-size: 30px;
  font-weight: bold;
}

.congrats-byline {
  font-size: 20px;
}

.time {
  font-size: 24px;
}

.finalTime {
  font-size: 28px;
  font-weight: bold;
}

.cancel {
  position: absolute;
  top: -4px;
  right: -4px;
}

.share {
  background-color: black;
  padding: 12px;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  border: none;
}

.share:active {
  background-color: #666;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.pulsing {
  animation: pulse 1s infinite;
}

.stage-button-container {
  display: flex;
  flex: 1;
  justify-content: space-around;
}

@media (min-width: 700px) {
  .game-board {
    display: grid;
    grid-template-columns: 150px 150px 150px 150px; /* Adjust based on number of cards */
    gap: 10px;
    margin: 20px;
  }
  .card {
    width: 150px; /* Adjust size as needed */
    height: 150px;
    background-color: #f0f0f0;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    font-size: 48px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
  }
  .stage-button {
    max-width: 620px;
    height: 150px;
  }
  .modal {
    max-width: 400px;
  }
}

.grid-loader {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  z-index: -1;
}

.grid-square {
  width: 30px;
  height: 30px;
  background-color: #4CAF50;
  opacity: 0.8;
  border-radius: 5px;
  animation: loadingAnimation 1.5s infinite;
}

@keyframes loadingAnimation {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
}

/* Specific animation delays for each square */
.grid-loader .grid-square:nth-child(1) { animation-delay: 0s; }
.grid-loader .grid-square:nth-child(2) { animation-delay: 0.1s; }
.grid-loader .grid-square:nth-child(3) { animation-delay: 0.2s; }
.grid-loader .grid-square:nth-child(4) { animation-delay: 0.3s; }
.grid-loader .grid-square:nth-child(5) { animation-delay: 0.4s; }
.grid-loader .grid-square:nth-child(6) { animation-delay: 0.5s; }
.grid-loader .grid-square:nth-child(7) { animation-delay: 0.6s; }
.grid-loader .grid-square:nth-child(8) { animation-delay: 0.7s; }
.grid-loader .grid-square:nth-child(9) { animation-delay: 0.8s; }
.grid-loader .grid-square:nth-child(10) { animation-delay: 0.9s; }
.grid-loader .grid-square:nth-child(11) { animation-delay: 1.0s; }
.grid-loader .grid-square:nth-child(12) { animation-delay: 1.1s; }
.grid-loader .grid-square:nth-child(13) { animation-delay: 1.2s; }
.grid-loader .grid-square:nth-child(14) { animation-delay: 1.3s; }
.grid-loader .grid-square:nth-child(15) { animation-delay: 1.4s; }
.grid-loader .grid-square:nth-child(16) { animation-delay: 1.5s; }


.load-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.beta {
  font-size: 30px;
}

.flipped-flag {
  display: inline-block;
  transform: rotateY(180deg);
}